<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="{span: 20,offset: 2}" :sm="{span: 16, offset: 4}">
        <!-- <div class="top">
          <div class="logo" :style="'background:url('+PlatformLogo+') no-repeat center;backgroundSize: auto 100%;'">
          </div>
          <div class="slogan">{{PlatformTitle}}</div>
        </div> -->
        <div class="main">
          <h2>隐私政策</h2>
          <p>{{PlatformTitle}}尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，{{PlatformTitle}}会按照本隐私权政策的规定使用和披露您的个人信息。但{{PlatformTitle}}将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，{{PlatformTitle}}不会将这些信息对外披露或向第三方提供。{{PlatformTitle}}会不定期更新本隐私权政策。 您在同意{{PlatformTitle}}服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于{{PlatformTitle}}服务使用协议不可分割的一部分。</p>
          <h3>1. 适用范围</h3>
          <p>
            a) 在您注册{{PlatformTitle}}帐号时，您根据{{PlatformTitle}}要求提供的个人注册信息；
            <br />b) 在您使用{{PlatformTitle}}网络服务，或访问{{PlatformTitle}}平台网页时，{{PlatformTitle}}自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
            <br />c) {{PlatformTitle}}通过合法途径从商业伙伴处取得的用户个人数据。
            <br />
            <strong>您了解并同意，以下信息不适用本隐私权政策：</strong>
            <br />a) 您在使用{{PlatformTitle}}平台提供的搜索服务时输入的关键字信息；
            <br />b) {{PlatformTitle}}收集到的您在{{PlatformTitle}}发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
            <br />c) 违反法律规定或违反{{PlatformTitle}}规则行为及{{PlatformTitle}}已对您采取的措施。
          </p>
          <h3>2. 信息使用</h3>
          <p>
            a) {{PlatformTitle}}不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和{{PlatformTitle}}（含{{PlatformTitle}}关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
            <br />b) {{PlatformTitle}}亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何{{PlatformTitle}}平台用户如从事上述活动，一经发现，{{PlatformTitle}}有权立即终止与该用户的服务协议。
            <br />c) 为服务用户的目的，{{PlatformTitle}}可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与{{PlatformTitle}}合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
          </p>
          <h3>3. 信息披露</h3>
          <p>
            <strong>在如下情况下，{{PlatformTitle}}将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</strong>
            <br />a) 经您事先同意，向第三方披露；
            <br />b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
            <br />c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
            <br />d) 如您出现违反中国有关法律、法规或者{{PlatformTitle}}服务协议或相关规则的情况，需要向第三方披露；
            <br />e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
            <br />f) 在{{PlatformTitle}}平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，{{PlatformTitle}}有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
            <br />g) 其它{{PlatformTitle}}根据法律、法规或者网站政策认为合适的披露。
          </p>
          <h3>4. 信息存储和交换</h3>
          <p>{{PlatformTitle}}收集的有关您的信息和资料将保存在{{PlatformTitle}}及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或{{PlatformTitle}}收集信息和资料所在地的境外并在境外被访问、存储和展示。</p>
          <h3>5. Cookie的使用</h3>
          <p>
            a) 在您未拒绝接受cookies的情况下，{{PlatformTitle}}会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的{{PlatformTitle}}平台服务或功能。{{PlatformTitle}}使用cookies可为您提供更加周到的个性化服务，包括推广服务。
            <br />b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的{{PlatformTitle}}网络服务或功能。
            <br />c) 通过{{PlatformTitle}}所设cookies所取得的有关信息，将适用本政策。
          </p>
          <h3>6. 信息安全</h3>
          <p>
            a) {{PlatformTitle}}帐号均有安全保护功能，请妥善保管您的用户名及密码信息。{{PlatformTitle}}将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
            <br />b) 在使用{{PlatformTitle}}网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是{{PlatformTitle}}用户名及密码发生泄露，请您立即联络{{PlatformTitle}}客服，以便{{PlatformTitle}}采取相应措施，但并不表示{{PlatformTitle}}对该行为必然承担责任。
          </p>
          <h3>7. 免责声明</h3>
          <p>
            a) 您在下载并浏览系统时均已视为已仔细浏览过本政策条款，并且对条款内容完全同意，自愿接受本系统相关政策的约束。
            <br />b)系统只保证存储3个月的数据，这些数据包括但不限于：已过期设备信息、 历史轨迹、指令记录、报警、操作日志等。实际可能3个月前的数据仍可查询，但是并不能确保。
            <br />c) 系统提供多种地图供用户选择，包括百度地图、高德地图、腾讯地图、谷歌地图、Openstreet等，但是并不能保证在所有国家/地区都提供这些地图，会根据用户所在的国家/地区、操作系统为用户提供至少一种可用的地图。
            <br />d){{PlatformTitle}}尊重并保护所有使用该系统用户的个人隐私权，您注册的用户名、密码、手机号码、电子邮件等个人资料，非经您亲自许可或根据相关法律、法规的强制性规定，{{PlatformTitle}}不会主动地泄露给第三方，如果您未保管好自己的账号、密码等个人资料，而对您、{{PlatformTitle}}或第三方造成的损害，您将负全部责任。
            e){{PlatformTitle}}为用户提供稳定可靠的硬件设备和软件系统服务，但是由于设备失效、信息不准确等造成的财产损失不负责任，只在质保期内提供设备的维修服务。
            <br />f){{PlatformTitle}}不承诺本系统或其任何内容、服务或功能无任何错误或不会中断，不承诺任何缺陷将得到更正，或者您对本系统的使用会产生特定结果。
            <br />g) 上述免责声明适用于因任何性能故障、错误、遗漏、中断、删除、缺陷、操作或传输延迟、电脑病毒、通信线路故障、失窃、毁坏、未经授权的访问、篡改或使用 (无论是出于违约、侵权、疏忽或任何其他诉因) 而导致的任何损害、责任或伤害。
            <br />h){{PlatformTitle}}有权随时自行决定更改、修改、添加或删除本使用条款的部分内容。您有责任定期检查本使用条款，以了解是否发生了更改。如果您在更改发布后继续使用本系统，即表示您接受并同意这些更改。
          </p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "securityProtocol",
  data() {
    return {
      PlatformTitle:'365汽车在线',
      PlatformLogo:''
    };
  },
  created(){
    console.log(this.$route.query)
    if (this.$route.query && this.$route.query.company) {
      this.PlatformTitle = this.$route.query.platform;
    } else if (localStorage.getItem("__last_login_info__")) {
      let info = JSON.parse(localStorage.getItem("__last_login_info__"));
      this.PlatformTitle = info.PlatformTitle
    } else {
      this.PlatformTitle = "365汽车在线";
    }
  }
};
</script>

<style lang="scss" scoped>
body{
  width: 100%;
  overflow: hidden;
}
.top {
  width: 100%;
  min-height: 100px;
  .logo {
    width: 200px;
    height: 100px;
    margin-right: 10px;
    float: left;
    background-color: #ccc;
    img {
      display: inline-block;
      width: 100%;
    }
  }
  .slogan {
    float: left;
    font-size: 24px;
    line-height: 100px;
    color: #a1a1a1;
  }
}
.main {
  font-family: "hyqh", "Microsoft YaHei", Verdana, Arial;
  padding: 50px 0;
  width: 100%;
  h2 {
    color: #e60012;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    padding-bottom: 30px;
  }
  h3 {
    font-weight: bold;
    color: #333;
    font-size: 20px;
    padding-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
    color: #666;
    padding-bottom: 20px;
    padding-left: 14px;
    strong {
      display: inline-block;
      padding: 5px;
      font-size: 16px;
    }
  }
}
</style>
